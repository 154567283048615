<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close" @closed="closed" >
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="标题" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="开始时间" prop="start_time">
						<el-date-picker type="datetime" v-model="form.start_time"/>
					</el-form-item>
					<el-form-item label="结束时间" prop="end_time">
						<el-date-picker type="datetime" v-model="form.end_time"/>
					</el-form-item>
					<el-form-item label="嘉宾" prop="speaker">
						<form-list v-model="form.speaker" />
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {plan as api} from "@/api/admin";
import _ from "lodash";
import moment from "moment";

export default {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入标题"}
				],
				start_time:[
					{required:true,message:"请选择开始时间"}
				]
			}
		}
	},
	methods:{
		getDetail(){
			api.get({
				id:this.query.id
			}).then((res)=>{
				const data = res.detail;
				this.form = this.decodeData(data);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data = this.encodeData(data);

				this.submit_loading = true;
				api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		decodeData(data){
			data.status = data.status === 1;
			data.start_time = data.start_time ? moment.unix(data.start_time).format('YYYY-MM-DD HH:mm:ss') : '';
			data.end_time = data.end_time ? moment.unix(data.end_time).format('YYYY-MM-DD HH:mm:ss') : '';

			return data;
		},
		encodeData(data){
			data.status = data.status ? 1 : 2;
			data.end_time = data.end_time ? moment(data.end_time).unix() : null
			data.start_time = data.start_time ? moment(data.start_time).unix() : null
			return data;
		},
		init(query = {}){
			this.query = query;
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
			this.visible = true;
		},
		close(){
			this.visible = false;
		},
		closed(){
			this.form = {};
			this.$refs['formRef'].resetFields();
		}
	}
}
</script>
